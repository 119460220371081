import React, { useEffect, useState } from "react";
import firebase from '../../config/firebase';
import { useNavigate, useParams, Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputMask from 'react-input-mask';
import PersistentDrawerLeft from "../../components/navMenu/NavMenu";
import BackButton from "../../components/backButton/BackButton";
require('firebase/auth')

function NewEmbarcador(){
    
    const {id} = useParams();

    let navigate = useNavigate();

    const [socialName, setSocialName] = useState("");
    const [nameFantasy, setNameFantasy] = useState("");
    const [documentNumber, setDocumentNamber] = useState("");
    
    const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
    const [phoneNumberSecond, setPhoneNumberSecond] = useState("");

    const [cep, setCep] = useState("");
    const [neighborhood, setNeighborhood] = useState("");//bairro
    const [street, setStreet] = useState("");//endereço
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [msgTipo, setMsgTipo] = useState();
    const [carregando, setCarregando] = useState(0);
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState();
    const [title, setTitle] = useState();

    const db = firebase.firestore();

    useEffect(() => {

        firebase.firestore().collection('shipper').doc(id)
            .get().then( async (result) => {
                
                var data = result.data();
                if(id) {
                    setSocialName(data.dataPersonal.socialName)
                    setDocumentNamber(data.dataPersonal.documentNumber)
                    setNameFantasy(data.dataPersonal.nameFantasy)
                    setPhoneNumberFirst(data.contact.phoneNumberFirst)
                    setPhoneNumberSecond(data.contact.phoneNumberSecond)
    
                    setCep(data.address.cep)
                    setStreet(data?.address.street)
                    setNeighborhood(data?.address.neighborhood)
                    setCity(data?.address.city)
                    setState(data?.address.state)
                }

            }).catch(error => {
                setCarregando(0)
                console.log(error)
            });
            
    },[carregando]);
        

    //Função para consultar CEP
    function buscarCep(input) {

        if(input != null && input.length < 8) {
            return;
        } else {
            
            fetch('https://viacep.com.br/ws/' + input + '/json/', {mode: 'cors'})
            .then((res) => res.json())
            .then((data) => {
                if (data.hasOwnProperty("erro")) {
                    alert('Cep não existente');
                } else {


                    setStreet(data?.logradouro)
                    setNeighborhood(data?.bairro)
                    setCity(data?.localidade)
                    setState(data?.uf)

                }
            })
            .catch(err => console.log(err));
        }
    }

    function buscarCNPJ(input) {
        if(input.length === 14) {
            fetch('https://api.cnpjs.dev/v1/'+input, {mode: 'cors'})
            .then((res) => res.json())
            .then((data) => {
                if (data.hasOwnProperty("erro")) {
                    alert('CNPJ não existente');
                } else {
                    setSocialName(data?.razao_social)
                    setDocumentNamber(data?.cnpj)
                    setNameFantasy(data?.nome_fantasia ? data?.nome_fantasia : data?.razao_social)
                    setPhoneNumberFirst(data?.telefone1)
                    setPhoneNumberSecond(data?.ddd_telefone_2 ? "" : data?.telefone1)
                    setCep(data?.endereco.cep)
                    setStreet(data?.endereco.logradouro + ' - ' + data?.endereco.complemento + ',' + data?.endereco.numero)
                    setNeighborhood(data?.endereco.bairro)
                    setCity(data?.endereco.municipio)
                    setState(data?.endereco.uf)
                }
            })
            .catch(err => console.log(err));
        } else if (input.length === 18) {
            const inputLimpo = input.replace(/[^\d]/g, "");
            fetch('https://api.cnpjs.dev/v1/'+inputLimpo, {mode: 'cors'})
            .then((res) => res.json())
            .then((data) => {
                if (data.hasOwnProperty("erro")) {
                    alert('CNPJ não existente');
                } else {
                    setSocialName(data?.razao_social)
                    setDocumentNamber(data?.cnpj)
                    setNameFantasy(data?.nome_fantasia ? data?.nome_fantasia : data?.razao_social)
                    setPhoneNumberFirst(data?.telefone1)
                    setPhoneNumberSecond(data?.ddd_telefone_2 ? "" : data?.telefone1)
                    setCep(data?.endereco.cep)
                    setStreet(data?.endereco.logradouro + ' - ' + data?.endereco.complemento + ',' + data?.endereco.numero)
                    setNeighborhood(data?.endereco.bairro)
                    setCity(data?.endereco.municipio)
                    setState(data?.endereco.uf)
                }
            })
            .catch(err => console.log(err));
        } else {
            return;
        }
    }

    function save(){
        setCarregando(1)
        var history = {
            created: new Date()
        }

        var dataPersonal = {
            socialName: socialName,
            documentNumber: documentNumber,
            nameFantasy: nameFantasy
        }

        var contact = {
            phoneNumberFirst: phoneNumberFirst,
            phoneNumberSecond: phoneNumberSecond
        }

        var address = {
            cep: cep,
            street: street,
            neighborhood: neighborhood,
            city: city,
            state: state
        }


        var dataAll = {
            dataPersonal: dataPersonal,
            contact: contact,
            address: address,
            history: history
        }


        db.collection("shipper").add(dataAll).then(() => {
            setCarregando(0)
            setMsgTipo('sucesso')
            
            navigate("/embarcador");
            
        }).catch(error => {
            setMsgTipo('erro')
            setCarregando(0)
            setTitle(' Ops!')
            setMsg('Houve um problema interno ao recuperar os dados do Embarcador');
            setOpen(true)
        });
    }

    const handleClose = () => {
        setOpen(false);
    };

    function update(){
        setCarregando(1)
        var history = {
            created: new Date()
        }

        var dataPersonal = {
            socialName: socialName,
            documentNumber: documentNumber,
            nameFantasy: nameFantasy
        }

        var contact = {
            phoneNumberFirst: phoneNumberFirst,
            phoneNumberSecond: phoneNumberSecond
        }

        var address = {
            cep: cep,
            street: street,
            neighborhood: neighborhood,
            city: city,
            state: state
        }


        var dataAll = {
            dataPersonal: dataPersonal,
            contact: contact,
            address: address,
            history: history
        }


        db.collection("shipper").doc(id).update(dataAll).then(() => {
            setCarregando(0)
            setMsgTipo('sucesso')
            
            navigate("/embarcador");
            
        }).catch(error => {
            setMsgTipo('erro')
            setCarregando(0)
        });

    }
    
    return(
        <>
            <PersistentDrawerLeft divOpen={ 

                <div 
                    className="freight-content align-items-center"
                    style={{ paddingTop: '0px' }}
                >
                    
                    <div className="form-cadastro">
                        
                        <div className="form-signin mx-auto mb-4 mb-lg-6">

                            <div className="col-12">
                                <h4 className="mb-3">Dados pessoais</h4>
                            </div>

                            <div className="form-signin mx-auto mb-4 mb-lg-6">
                                <div className="row">
                                    
                                        <div className="col-md-2">
                                            <label htmlFor="documentNumber" className="form-label">CNPJ</label>

                                            <input type="text" 
                                                    onChange={(e)=> {
                                                    buscarCNPJ(e.target.value);
                                                    setDocumentNamber(e.target.value);
                                                }} 
                                                value={documentNumber && documentNumber} 
                                                className="form-control" id="documentNumber"/>

                                        </div>
                                    
                                        <div className="col-md-5">
                                            <label htmlFor="socialName" className="form-label">Razão Social</label>
                                            <input type="text" 
                                                onChange={(e)=> setSocialName(e.target.value)} 
                                                value={socialName && socialName} 
                                                className="form-control" id="socialName"/>
                                        </div>

                                        <div className="col-md-5">
                                            <label htmlFor="nameFantasy" className="form-label">Nome Fantasia</label>
                                            <input type="text" 
                                                onChange={(e)=> setNameFantasy(e.target.value)} 
                                                value={nameFantasy && nameFantasy} 
                                                className="form-control" id="nameFantasy"/>
                                        </div>
                                    
                                </div>
                            </div>

                            <div className="form-signin mx-auto mb-4 mb-lg-6">
                                <div className="row">

                                    <div className="col-12">
                                        <h4 className="mb-3">Endereço</h4>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-2">
                                            <label htmlFor="cep"  className="form-label">CEP</label>
                                            <InputMask mask="99999-999" type="text"  
                                                onChange={(e)=> {
                                                    buscarCep(e.target.value);
                                                    setCep(e.target.value);
                                                    
                                                }}
                                                value={cep && cep}  
                                                className="form-control" 
                                                id="cep" 
                                            />

                                        </div>
                                        <div className="col-4">
                                            <label htmlFor="street" className="form-label">Endereço</label>
                                            <input type="text"  
                                                onChange={(e)=> setStreet(e.target.value)} 
                                                value={street && street}  
                                                className="form-control" 
                                                id="street" 
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="neighborhood" className="form-label">Bairro</label>
                                            <input type="text" 
                                                onChange={(e)=> setNeighborhood(e.target.value)} 
                                                value={neighborhood && neighborhood} 
                                                className="form-control" 
                                                id="neighborhood"/>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="city" className="form-label">Cidade</label>
                                            <input type="text" 
                                                onChange={(e)=> setCity(e.target.value)} 
                                                value={city && city} 
                                                className="form-control" 
                                                id="neighborhood"/>
                                        </div>

                                        <div className="col-md-1">
                                            <label htmlFor="state" className="form-label">Estado</label>
                                            <input type="text"  
                                                onChange={(e)=> setState(e.target.value)} 
                                                value={state && state}  
                                                className="form-control" id="state"/>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="form-signin mx-auto mb-4 mb-lg-6">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="mb-3">Contato</h4>
                                    </div>

                                    <div className="col-md-4">
                                        <label htmlFor="phoneNumberFirst" className="form-label">Telefone </label>
                                        <InputMask mask="(99) 9999-9999" 
                                                className="form-control" 
                                                onChange={(e)=> setPhoneNumberFirst(e.target.value)} 
                                                value={phoneNumberFirst && phoneNumberFirst} 
                                                placeholder="(99) 99999-9999"/>
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="phoneNumberSecond" className="form-label">Telefone </label>
                                            <InputMask mask="(99) 99999-9999" 
                                                className="form-control" 
                                                onChange={(e)=> setPhoneNumberSecond(e.target.value)} 
                                                value={phoneNumberSecond && phoneNumberSecond} 
                                                placeholder="(99) 99999-9999"/>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-4"></div>
                                    <div className="col-4"></div>
                                    <div className="col-4" style={{ display: 'flex', gap: '10px' }}>
                                        <div className="btn-class-cadastrar" style={{ flex: 1 }}>
                                            <Link to={"/embarcador"}>
                                                <BackButton />
                                            </Link>
                                        </div>
                                        <div className="btn-class-cadastrar" style={{ flex: 1 }}>
                                            {
                                                carregando ? 
                                                <div className="spinner-border text-danger" role="status">
                                                <span className="visually-hidden ">Loading...</span></div>
                                                :
                                                <button type="submit" onClick={id ? update : save} className="w-100 btn btn-primary btn-cadastrar">{id ? 'Editar' : 'Cadastrar'}</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                                <DialogTitle id="alert-dialog-title">
                                <h1>
                                    <i  class="fa fa-exclamation-triangle" style={{color: '#FEDE00'}} aria-hidden="true"></i> 
                                    {title}
                                </h1>
                                
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                    <h6> {msg} </h6>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                
                                    <button 
                                        type="button" 
                                        onClick={handleClose} 
                                        className="btn btn-primary btn-cadastrar"> Ok</button>

                                </DialogActions>
                            </Dialog>
                        </div>

                    </div>
                </div>
            }/>
        </>
    )
}

export default NewEmbarcador;