import { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import '../uploadFile/upload.css'
import { MdCloudUpload, MdDelete } from 'react-icons/md'
import { AiFillFileImage } from "react-icons/ai";

export default function AlertDialog({ handleClose, open, origem, handleUpload }) {
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [fileName, setFileName] = useState("No selected file");
    const [uploading, setUploading] = useState(false); 

    const handleImageChange = ({ target: { files } }) => {
        const file = files[0];
        if (file) {
            setFileName(file.name);
            setImage(URL.createObjectURL(file));
            setImageUrl(file);
        }
    };

    const handleUploadClick = async () => {
        setUploading(true); 
        try {
            await handleUpload(imageUrl);
            setUploading(false);

            setFileName("Imagem não selecionada");
            setImage(null);
            setImageUrl(null);
        } catch (error) {
            console.error("Erro ao fazer upload:", error);
            setUploading(false); 
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Importar documento do(a) " + origem}
                </DialogTitle>
                <DialogContent>
                    <main>
                        <form onClick={() => document.querySelector(".input-field").click()}>
                            <input
                                type="file"
                                accept="image/*"
                                className='input-field'
                                hidden
                                onChange={handleImageChange}
                            />
                            {image ? (
                                <img 
                                    src={image}
                                    alt={fileName}
                                    style={{
                                        maxWidth: '498px', 
                                        maxHeight: '300px', 
                                        width: 'auto', 
                                        height: 'auto',
                                        display: 'block',
                                        margin: '0 auto' // Opcional: centraliza a imagem
                                    }}
                                />
                            ) : (
                                <>
                                    <MdCloudUpload color='#1475cf' size={60} />
                                    <p>{uploading ? "Aguardando upload..." : "Escolha a imagem para upload"}</p>
                                </>
                            )}
                        </form>
                        <section className='uploaded-row'>
                            <AiFillFileImage color="#1475cf" />
                            <span>
                                {fileName} -
                                <MdDelete
                                    onClick={() => {
                                        setFileName("Imagem não selecionada");
                                        setImage(null);
                                        setImageUrl(null);
                                    }}
                                />
                            </span>
                        </section>
                    </main>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleUploadClick}
                        disabled={!imageUrl || uploading}
                    >
                        Importar
                    </Button>
                    <Button
                        onClick={() => {
                            handleClose();
                            setFileName("Imagem não selecionada");
                            setImage(null);
                            setImageUrl(null);
                        }}
                        color="error"
                        autoFocus
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
