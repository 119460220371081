import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate} from "react-router-dom";
import PersistentDrawerLeft from "../../components/navMenu/NavMenu";
import firebase from '../../config/firebase';
import DataClientTable from "../../components/Data-client-table/data-client-table";
import { Button } from "antd";
import AddIcon from '@mui/icons-material/Add';
import './client.css';
require('firebase/auth')

function ClientList(){
    const db = firebase.firestore();

    const [listClient, setListClient] = useState([]);
    const [carregando, setCarregando] = useState(1);

    let clientList = [];

    var userLogado = useSelector(state => state.usuarioLogado);
    const user = useSelector(state => state.user)

    useEffect(() => {

        if(userLogado > 0) {

            if(user.perfil === "Master"){
                db.collection('client')
                .get().then( async (result) => {
                    result.docs.forEach(doc => {
                        clientList.push({
                            id: doc.id,
                            ...doc.data()
                        });
                    })
                    
                    setListClient(clientList)
                    setCarregando(0)
                }).catch(error => {
                    setCarregando(0)
                    console.log(error)
                });

            }else{
            
                // db.collection('client')
                // .doc(user.uidShipper)
                // .get().then( async (result) => {
                    
                //     clientList.push({
                //         id: result.id,
                //         ...result.data()
                //     });

                //     setListClient(clientList)
                //     setCarregando(0)
                // Codigo Athos ☝️

                db.collection('client')
                .get().then( async (result) => {
                    const filtroCliente = [];
                    result.docs.forEach(doc => {
                        const clienteData = doc.data();
                        if (clienteData.uidShipper && clienteData.uidShipper !== null) {
                            filtroCliente.push({
                                id: doc.id,
                                ...clienteData
                            });
                        }
                    });

                    setListClient(filtroCliente);
                    setCarregando(0);
                }).catch(error => {
                    setCarregando(0)
                    console.log(error)
                });
            }
        }
    },[carregando]);

    return(
        <>
            { 
                useSelector(state => state.usuarioLogado) > 0 ? 
                    <PersistentDrawerLeft divOpen={
                        <div className="freight-content">
                            <div className="container mb-3">
                                <div className="row">
                                    <div className="col-5">
                                        <h4>Clientes</h4>
                                    </div>
                                    <div className="col-5">
                                    </div>
                                    <div className="col-2">
                                        <div className="col-md-11 control-label">
                                            <Link to={"/client"}>
                                                <Button id="ButtonHover" type="primary" icon={<AddIcon />}>
                                                    Novo
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>        
                            </div>
                            <div>
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataClientTable data={listClient}/>
                                    {/* <div style={{ display: 'flex', height: '100%' }}>
                                        <div style={{ flexGrow: 1 }}>
                                            <CustomClientActionsTable lista={listClient} />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                }/>
                : 
                <Navigate to='/login' />
            }
        </>
    );
}

export default ClientList;