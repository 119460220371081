import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask'
import firebase, { storage } from '../../config/firebase';
import PersistentDrawerLeft from "../../components/navMenu/NavMenu";
import ImageZoom from "../../components/imageZoom";
import { format } from 'date-fns';
import ProfileCard from "../../components/Profile/cardprofile";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Button from '@mui/material/Button';
import AlertDialog from "../../components/dialog";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import PasswordInput from "../../components/PasswordInput/passwordInput";

require('firebase/auth')

function Driver(){

    const {id} = useParams();

    const [carregando, setCarregando] = useState();

    const [status, setStatus] = useState("authorized");
    const [name, setName] = useState("");
        
    const [created, setCreated] = useState("");
    const [lastUpdated, setLastUpdated] = useState("");
    const [birthDate, setBirthDate] = useState();
    const [documentCnhExpiration, setDocumentCnhExpiration] = useState("");
    const [lastUpdatedBackOffice, setLastUpdatedBackOffice] = useState("");
    
    //Address
    const [cep, setCep] = useState("");
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [number, setNumber] = useState("");
    const [state, setState] = useState("");
    const [street, setStreet] = useState("");

    //dataBank
    const [accountDigit, setAccountDigit] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [agency, setAgency] = useState("");
    const [bankName, setBankName] = useState("");
    const [code, setCode] = useState("");
    const [holderAccount, setHolderAccount] = useState("");
    const [ispb, setIspb] = useState("");
    const [pix, setPix] = useState("");

    //kinships
    const [contactKinships1, setContactKinships1] = useState("");
    const [degreeKinships1, setDegreeKinships1] = useState("");
    const [nameKinships1, setNameKinships1] = useState("");
    const [contactKinships2, setContactKinships2] = useState("");
    const [degreeKinships2, setDegreeKinships2] = useState("");
    const [nameKinships2, setNameKinships2] = useState("");     
    //PersonalData

    const [contact, setContact] = useState("");
    const [documentAnttFrontImg, setDocumentAnttFrontImg] = useState(null);
    const [documentAnttVerseImg, setDocumentAnttVerseImg] = useState(null);
    const [documentCnhFrontImg, setDocumentCnhFrontImg] = useState(null);
    const [documentCnhVerseImg, setDocumentCnhVerseImg] = useState(null);
    const [fullName, setFullName] = useState("");
    const [sexGender, setSexGender] = useState("");

    //ProfessionalRerence
    const [contactProfessional, setContactProfessional] = useState("");
    const [nameProfessional, setNameProfessional] = useState("");
    const [contactProfessional1, setContactProfessional1] = useState("");
    const [nameProfessional1, setNameProfessional1] = useState("");

    // vehicle
    const [bodyworkPlate, setBodyworkPlate] = useState("");
    const [bodyworkType, setBodyworkType] = useState("");
    const [vehicleImg, setVehicleImg] = useState(null);
    const [vehiclePlate, setVehiclePlate] = useState("");
    const [vehicleType, setVehicleType] = useState("");    


    const [cpf, setCpf] = useState();
    const [email, setEmail] = useState();
    const [profilePicture, setProfilePicture] = useState();
    const [dateRenovation, setDateRenovation] = useState();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [msgTipo, setMsgTipo] = useState();
    const [msg, setMsg] = useState();
    const [title, setTitle] = useState();

    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [origem, setOrigem] = useState("");
    const [progresspercent, setProgresspercent] = useState(0);

    const db = firebase.firestore();

    const users = useSelector(state => state.user);
    const shippers = useSelector(state => state.shipper);
    
    let navigate = useNavigate();

    useEffect(() => {
    
        if(id){

            db.collection('drivers_users').doc(id).get().then(result => {
                    var driver = result.data();

                    setStatus(driver?.statusDriver) 
                    setEmail(driver?.email)
                    setProfilePicture(driver?.profilePicture)
                    setCpf(driver?.cpf)
                    
                    setName(driver?.name)
            });
    
            db.collection('drivers_users').doc(id).collection('documents').doc("allData")
            .get().then( async (result) => {
                
                var allData = result.data();
    
    
                let birthDate = allData?.birthDate;
                let created = allData?.created;
                let lastUpdated = allData?.lastUpdated;
                let personalData = allData?.personalData;
                let dataBank = allData?.dataBank;
                let dataCnhExpiration = allData?.documentCnhExpiration;
                let kinships = allData?.kinships;
                let profissionalReference = allData?.professionalReference;
                let vehicle = allData?.vehicle;
                let address = allData?.address;
                let lastUpdatedBackOffice = allData?.lastUpdatedBackOffice;
                let dateRenovation = allData?.dateRenovation;
                
                let dataCreated = created ? format(new Date(created.seconds * 1000), 'yyyy-MM-dd') : "";
                let dataLastUpdate = lastUpdated ? format(new Date(lastUpdated.seconds * 1000), 'yyyy-MM-dd') : "";
                let dataCnhExpired = dataCnhExpiration ? format(new Date(dataCnhExpiration.seconds * 1000), 'yyyy-MM-dd') : "";
                let dataBirthDay = birthDate ? format(new Date(birthDate.seconds * 1000), 'yyyy-MM-dd') : "";
                let dataLastUpdateBack =  lastUpdatedBackOffice ?  format(new Date(lastUpdatedBackOffice.seconds * 1000), 'yyyy-MM-dd') : "";
                let dateRenovationParse =  dateRenovation ?  format(new Date(dateRenovation.seconds * 1000), 'yyyy-MM-dd') : "";
    
                setBirthDate(dataBirthDay)
                // setCreated(dataCreated)
                setLastUpdated(dataLastUpdate)
                setLastUpdatedBackOffice(dataLastUpdateBack)
                setDateRenovation(dateRenovationParse)
    
                //Personal data
                setContact(personalData?.contact);
                setDocumentAnttFrontImg(personalData?.documentAnttFrontImg);
                setDocumentAnttVerseImg(personalData?.documentAnttVerseImg);
                setDocumentCnhFrontImg(personalData?.documentCnhFrontImg);
                setDocumentCnhVerseImg(personalData?.documentCnhVerseImg);
                setFullName(personalData?.fullName);
                setSexGender(personalData?.sexGender);
                setDocumentCnhExpiration(dataCnhExpired)
    
                //Vehicle
                setBodyworkPlate(vehicle?.bodyworkPlate)
                setBodyworkType(vehicle?.bodyworkType)
                setVehicleImg(vehicle?.vehicleImg)
                setVehiclePlate(vehicle?.vehiclePlate)
                setVehicleType(vehicle?.vehicleType)
    
                //address 
                setCep(address?.cep)
                setCity(address?.city)
                setDistrict(address?.district)
                setNumber(address?.number)
                setState(address?.state)
                setStreet(address?.street)
    
                console.log(allData?.professionalData)

                //professionalData
                setContactProfessional(profissionalReference[0]?.contact)
                setNameProfessional(profissionalReference[0]?.name)
    
                setContactProfessional1(profissionalReference[1].contact)
                setNameProfessional1(profissionalReference[1].name)
    
                //kinships 
                setContactKinships1(kinships[0].contact)
                setDegreeKinships1(kinships[0].degree)
                setNameKinships1(kinships[0].name)
    
                setContactKinships2(kinships[1].contact)
                setDegreeKinships2(kinships[1].degree)
                setNameKinships2(kinships[1].name)
    
                //dataBank
                setAccountDigit(dataBank.accountDigit)
                setAccountNumber(dataBank.accountNumber)
                setAgency(dataBank.agency)
                setBankName(dataBank.bankName)
                setCode(dataBank.code)
                setHolderAccount(dataBank.holderAccount)
                setIspb(dataBank.ispb)
                setPix(dataBank.pix)
    
                setCarregando(0)
            }).catch(error => {
                setCarregando(0)
                console.log(error)
            });

        }

        
    },[carregando]);

    function showMessage(msg){
        setMsgTipo('erro');
        setCarregando(0)
        setTitle(' Ops!')
        setMsg(msg);
        setOpenDialog(true)
        return;
    }

    var campos = ['fullName', 'cpf', 'password', 'confirmPassword', 'contact', 'formOfPayment', 'email', 'documentCnhExpiration'];
    for (var i = 0; i < campos.length; i++) {
        var campo = document.getElementById(campos[i]);
        if(campo) {
            campo.addEventListener('input', function() {
                this.style.borderColor = "";
            });
        }
    }

    function validInput(){
        var retorno = true;
        if(!password || password.length < 6){
            showMessage("O campo senha precisa ter no minímo 6 caracteres alfa numericos ");
            const input = document.getElementById("password");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }
        if(password !== confirmPassword) {
            showMessage("Senha diverge da confirmação");
            const input = document.getElementById("confirmPassword");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }

        if(!fullName){
            showMessage("O Nome do motorista precisa ser preenchido corretamente");
            const input = document.getElementById("fullName");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }
        if (!cpf) {
            showMessage("O CPF precisa ser preenchido corretamente");
            const input = document.getElementById("cpf");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }
        if (!birthDate) {
            showMessage("A Data de Nascimento precisa ser preenchido corretamente");
            const input = document.getElementById("birthDateDriver");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }
        if (!contact) {
            showMessage("O Contato precisa ser preenchido corretamente");
            const input = document.getElementById("contact");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }
        if (!sexGender) {
            showMessage("O Gênero precisa ser preenchido corretamente");
            const input = document.getElementById("formOfPayment");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }
        if (!email) {
            showMessage("O email precisa ser preenchido corretamente");
            const input = document.getElementById("email");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }
        if (!documentCnhExpiration) {
            showMessage("A data de expiração do CNH precisa ser preenchido corretamente");
            const input = document.getElementById("documentCnhExpiration");
            input.focus();
            input.style.borderColor = "red";
            retorno = false;
        }
        if (!documentAnttFrontImg) {
            showMessage("O campo 'Doc ANTT Frente' precisa ter uma imagem importada");
            document.getElementById("documentAnttFrontImg").focus();
            retorno = false;
        }
        if (!documentAnttVerseImg) {
            showMessage("O campo 'Doc ANTT Verso' precisa ter uma imagem importada");
            document.getElementById("documentAnttVerseImg").focus();
            retorno = false;
        }
        if (!documentCnhFrontImg) {
            showMessage("O campo 'CNH Frente' precisa ter uma imagem importada");
            document.getElementById("documentCnhFrontImg").focus();
            retorno = false;
        }
        if (!documentCnhVerseImg) {
            showMessage("O campo 'CNH Verso' precisa ter uma imagem importada");
            document.getElementById("documentCnhVerseImg").focus();
            retorno = false;
        }
        return retorno;
    }

    function save(){
        console.log('Salvando...') 
        if(validInput()){

            var history = {
                created: new Date(),
                userCreated: users.name
            }

            var address = {
                cep: withoutMaskCEP(cep),
                city: city ? city : "",
                district: district ? district : "",
                number: number ? number : "",
                state: state ? state : "",
                street: street ? street : ""
            }
    
            var dataBank = {
                accountDigit: accountDigit ? accountDigit : "",
                accountNumber: accountNumber ? accountNumber : "",
                agency: agency ? agency : "",
                bankName: bankName ? bankName : "",
                code: code ? code : "",
                holderAccount: holderAccount ? holderAccount : "",
                pix: pix ? pix : ""
            }
    
            var kinships = [
                {
                    contact: withoutMaskPhone(contactKinships1),
                    degree: degreeKinships1 ? degreeKinships1 : "",
                    name: nameKinships1 ? nameKinships1 : ""
        
                },
                {
                    contact: withoutMaskPhone(contactKinships2),
                    degree: degreeKinships2 ? degreeKinships2 : "",
                    name: nameKinships2 ? nameKinships2 : ""
                }
            ]
    
            var personalData = {
                contact: contact ? contact : "",
                documentAnttFrontImg: documentAnttFrontImg ? documentAnttFrontImg : "",
                documentAnttVerseImg: documentAnttVerseImg ? documentAnttVerseImg : "",
                documentCnhFrontImg:  documentCnhFrontImg ? documentCnhFrontImg : "",
                documentCnhVerseImg: documentCnhVerseImg ? documentCnhVerseImg : "",
                fullName: fullName ? fullName : "",
                sexGender: sexGender ? sexGender : "",
                documentCpf: cpf
            }
    
            var professionalData = [
                {
                    contact: withoutMaskPhone(contactProfessional),
                    name: nameProfessional ? nameProfessional : ""
                },
                {
                    contact: withoutMaskPhone(contactProfessional1),
                    name: nameProfessional1 ? nameProfessional1 : ""
                }
            ]
    
            var vehicle = {
                bodyworkPlate: bodyworkPlate ? bodyworkPlate : "",
                bodyworkType: bodyworkType ? bodyworkType : "",
                vehicleImg: vehicleImg ? vehicleImg : "",
                vehiclePlate: vehiclePlate ? vehiclePlate : "",
                vehicleType: vehicleType ? vehicleType : ""
            }

            let shipper = {
                uid: users.uidShipper,
                name: shippers.dataPersonal.socialName
            }

            if (birthDate && birthDate !== "") {
                let dataComSplit = birthDate.split("-");
                var dataNova = new Date(`${dataComSplit[0]}/${dataComSplit[1]}/${dataComSplit[2]}`);
            }

            if (dateRenovation && dateRenovation !== "") {
                let dataRenovSplit = dateRenovation.split("-");
                var dataRenovNova = new Date(`${dataRenovSplit[1]}/${dataRenovSplit[2]}/${dataRenovSplit[0]}`);
            }

            firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(async resultado => {

                let uid = resultado.user.uid;

                var data = {
                    birthDate: birthDate ? new Date(dataNova.toString()) : "",
                    documentCnhExpiration: documentCnhExpiration ? new Date(documentCnhExpiration) : "",
                    lastUpdatedBackOffice: new Date(),
                    dateRenovation: dateRenovation ? new Date(dataRenovNova.toString()) : "",
                    address: address,
                    dataBank: dataBank,
                    kinships: kinships,
                    personalData: personalData,
                    professionalReference: professionalData,
                    vehicle: vehicle,
                    shipper: shipper,
                    
                }

                var fistData = {
                    cpf: withoutMaskCPF(cpf),
                    email: email,
                    name: fullName,
                    uid: uid,
                    statusDriver: status,
                    accountCreated: new Date(),
                    history: history,
                    idNotification: '',
                }

                console.log(fistData)
    
                console.log('salvou usuário')

                await db.collection('drivers_users').doc(uid).set(fistData);
                //.then(async (item) => {

                    await db.collection('drivers_users').doc(uid)
                            .collection('documents').doc('allData').set(data).then(() => {
                                    console.log('allData')
                                    setCarregando(0) 
                                    setMsgTipo('sucesso');
                                    navigate("/driverList");
                            });
    
                // }).catch(error => {
                //     setCarregando(0)
                // });


                // await db.collection('drivers_users').add(fistData).then(async (item) => {
                //     console.log('drivers_users')

                //     await db.collection('drivers_users').doc(item.id)
                //             .collection('documents').doc('allData').set(data).then(() => {
                //                     console.log('allData')
                //                     setCarregando(0)
                //                     setMsgTipo('sucesso');
                //                     navigate("/driverList");
                //             });
                        


                //     // setCarregando(0)
                //     // navigate("/myDriverList");
    
                // }).catch(error => {
                //     setCarregando(0)
                // });

            })
            .catch(error => {
                setCarregando(0)
                setMsgTipo('erro')
                switch (error.message) {
                    case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
                        setMsg('A senha deve ter pelo menos 6 caracteres')
                        setMsgTipo('erro');
                        setTitle(' Oops!')
                        setOpenDialog(true)
                        break;
                    case 'Firebase: The email address is already in use by another account. (auth/email-already-in-use).':
                        setMsg('Este e-mail já está sendo utilizado por outro usuário')
                        setMsgTipo('erro');
                        setTitle(' Oops!')
                        setOpenDialog(true)
                        break;
                    case 'Firebase: The email address is badly formatted. (auth/invalid-email).':
                        setMsg('O formato do seu e-mail é inválido!')
                        setMsgTipo('erro');
                        setTitle(' Oops!')
                        setOpenDialog(true)
                        break;
                    default:
                        setMsg('Não foi possível cadastrar. Tente novamente mais tarde ou contate o administrador!')
                        setMsgTipo('erro');
                        setTitle(' Oops!')
                        setOpenDialog(true)
                        break;
                }
            });
        }
    }

    function update(){
        setCarregando(1);

        var history = {
            lastUpdateAutorized: new Date()
            // (status === "" ? new Date() : "")
        }
        var data = {
            email: email ? email : "",
            cpf: withoutMaskCPF(cpf),
            statusDriver: status ? status : "",
            history: history
        }

        var address = {
            cep: withoutMaskCEP(cep),
            city: city ? city : "",
            district: district ? district : "",
            number: number ? number : "",
            state: state ? state : "",
            street: street ? street : ""
        }

        var dataBank = {
            accountDigit: accountDigit ? accountDigit : "",
            accountNumber: accountNumber ? accountNumber : "",
            agency: agency ? agency : "",
            bankName: bankName ? bankName : "",
            code: code ? code : "",
            holderAccount: holderAccount ? holderAccount : "",
            ispb: ispb ? ispb : "",
            pix: pix ? pix : ""
        }

        var kinships = [
            {
                contact: withoutMaskPhone(contactKinships1),
                degree: degreeKinships1 ? degreeKinships1 : "",
                name: nameKinships1 ? nameKinships1 : ""
    
            },
            {
                contact: withoutMaskPhone(contactKinships2),
                degree: degreeKinships2 ? degreeKinships2 : "",
                name: nameKinships2 ? nameKinships2 : ""
            }
        ]

        var personalData = {
            contact: contact ? contact : "",
            documentAnttFrontImg: documentAnttFrontImg ? documentAnttFrontImg : "",
            documentAnttVerseImg: documentAnttVerseImg ? documentAnttVerseImg : "",
            documentCnhFrontImg:  documentCnhFrontImg ? documentCnhFrontImg : "",
            documentCnhVerseImg: documentCnhVerseImg ? documentCnhVerseImg : "",
            fullName: fullName ? fullName : "",
            sexGender: sexGender ? sexGender : ""
        }

        var professionalData = [
            {
                contact: withoutMaskPhone(contactProfessional),
                name: nameProfessional ? nameProfessional : ""
            },
            {
                contact: withoutMaskPhone(contactProfessional1),
                name: nameProfessional1 ? nameProfessional1 : ""
            }
        ]

        var vehicle = {
            bodyworkPlate: bodyworkPlate ? bodyworkPlate : "",
            bodyworkType: bodyworkType ? bodyworkType : "",
            vehicleImg: vehicleImg ? vehicleImg : "",
            vehiclePlate: vehiclePlate ? vehiclePlate : "",
            vehicleType: vehicleType ? vehicleType : ""
        }

        if (birthDate && birthDate !== "") {
            let dataComSplit = birthDate.split("-");
            var dataNova = new Date(`${dataComSplit[0]}/${dataComSplit[1]}/${dataComSplit[2]}`);
        }

        if (dateRenovation && dateRenovation !== "") {
            let dataRenovSplit = dateRenovation.split("-");
            var dataRenovNova = new Date(`${dataRenovSplit[1]}/${dataRenovSplit[2]}/${dataRenovSplit[0]}`);
        }

        var dataAll = {
            birthDate: birthDate ? new Date(dataNova.toString()) : "",
            documentCnhExpiration: documentCnhExpiration ? new Date(documentCnhExpiration) : "",
            lastUpdatedBackOffice: new Date(),
            dateRenovation: dateRenovation ? new Date(dataRenovNova.toString()) : "",
            address: address,
            dataBank: dataBank,
            kinships: kinships,
            personalData: personalData,
            professionalReference: professionalData,
            vehicle: vehicle
        }


        //Se a senha for 
        if(password){
            var user = firebase.auth().currentUser;

            user.updatePassword(password).then(() => {
                console.log("Password updated!");
            }).catch((error) => { 
                console.log(error); 

                switch (error.message) {
                    case 'Firebase: This operation is sensitive and requires recent authentication. Log in again before retrying this request. (auth/requires-recent-login).':
                        setMsg('Essa operação é muito sensiva e requer uma autenticação recente, por favor realizar o login novamente antes de alterar.')
                        setMsgTipo('erro');
                        setTitle(' Oops!')
                        setOpenDialog(true)
                        break;
                    default:
                        setMsg('Não foi possível cadastrar. Tente novamente mais tarde ou contate o administrador!')
                        setMsgTipo('erro');
                        setTitle(' Oops!')
                        setOpenDialog(true)
                        break;
                }

            });
        }

        console.log(data)
        console.log(dataAll)

        db.collection('drivers_users').doc(id).update(data).then(() => {
            db.collection('drivers_users').doc(id)
                .collection('documents')
                .doc("allData")
                .update(dataAll).then(() => {
                    setCarregando(0)
                    navigate("/driverList");        

            });

        }).catch(error => {
            setCarregando(0)
        });

    }

    function buscarCep(input) {

        if(input != null && input.length < 8) {
            return;
        } else {
            
            fetch('https://viacep.com.br/ws/' + input + '/json/', {mode: 'cors'})
            .then((res) => res.json())
            .then((data) => {
                if (data.hasOwnProperty("erro")) {
                    alert('Cep não existente');
                } else {


                    setStreet(data?.logradouro)
                    setDistrict(data?.bairro)
                    setCity(data?.localidade)
                    setState(data?.uf)

                }
            })
            .catch(err => console.log(err));
        }
    }

    function withoutMaskPhone(input){
        return (input ? input.replace("(","").replace(")","").replace(" ","").replace("-","") : "");
    }

    function withoutMaskCPF(input){
        return (input ? input.replace(".","").replace(".","").replace("-",""): "");
    }

    function withoutMaskCEP(input){
        return (input ? input.replace("-",""): "");
    }

    const handleUploadAntt = (event) => {
        event.preventDefault()
        
        const file = event.target[0]?.files[0]
        if(!file) return;

        const storageRef = ref(storage, `drivers_users/${id}/${file.name}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed", 
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log(downloadURL)
                    setDocumentAnttFrontImg(downloadURL)
                });
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleUpload = (file) => {
        // setCarregando(1)
        if(!file) return;

        console.log(file)
        var contest = file;
        console.log(contest)

        const storageRef = ref(storage, `drivers_users/${id}/${contest.name}`);

        const uploadTask = uploadBytesResumable(storageRef, contest);

        uploadTask.on("state_changed", 
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setCarregando(progress);
            },
            (error) => {
                alert(error)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                    if(origem === "ANTT Frente"){
                            setDocumentAnttFrontImg(downloadURL)
                            setCarregando(0)
                            setOpen(false)                
                            setOrigem("")
                            contest = null;
                            console.log(contest)
                    }else
                        if(origem === "ANTT Verso"){
                            setDocumentAnttVerseImg(downloadURL)
                            setCarregando(0)
                            setOpen(false)
                            setOrigem("")
                    }else 
                        if(origem === "CNH Frente"){
                            setDocumentCnhFrontImg(downloadURL)
                            setCarregando(0)
                            setOpen(false)
                            setOrigem("")
                    }else  
                        if(origem === "CNH Verso"){
                            setDocumentCnhVerseImg(downloadURL)
                            setCarregando(0)
                            setOpen(false)
                            setOrigem("")
                    }else 
                        if(origem === "Imagem veiculo"){
                            setVehicleImg(downloadURL)    
                            setCarregando(0)
                            setOpen(false)
                            setOrigem("")
                        }
                });
        })
    }

    return (
    <>
        { 
        useSelector(state => state.usuarioLogado) > 0 ?

            <PersistentDrawerLeft divOpen={

                <div 
                    className="freight-content align-items-center"
                    style={{ paddingTop: '0px' }}
                >

                    {/* Dialog de upload de arquivo  */}
                    <AlertDialog
                        handleClose={handleClose} 
                        open={open} 
                        origem={origem} 
                        handleUpload={handleUpload}
                    />

                    <div className="row g-12 ">

                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                            <input type="radio" class="btn-check" onChange={(e)=> setStatus(e.target.value) } checked={status && status === "authorized"} value="authorized" name="btnradio" id="btnradio3" autocomplete="off" />
                            <label class="btn btn-outline-primary" for="btnradio3">Autorizado</label>

                            <input type="radio" class="btn-check" onChange={(e)=> setStatus(e.target.value) } checked={status && status === "informed"} value="informed" name="btnradio" id="btnradio2" autocomplete="off" />
                            <label class="btn btn-outline-warning" for="btnradio2">Pendente</label>

                            <input type="radio" class="btn-check" onChange={(e)=> setStatus(e.target.value) } checked={status && status === "incomplete"} value="incomplete" name="btnradio" id="btnradio1" autocomplete="off" />
                            <label class="btn btn-outline-danger" for="btnradio1">Incompleto</label>

                        </div>
                        <hr className="my-4"/>
                    </div>

                    <div className="form-signin mx-auto mb-4 mb-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <ProfileCard imgProfile={profilePicture} />
                            </div>
                        </div>
                    </div>
                {/** Personal data */}
                <div className="form-signin mx-auto mb-4 mb-lg-6">

                    <div className="col-12">
                        <h4 className="mb-3">Dados pessoais</h4>
                    </div>
                    <div className="row">

                        <div className="row">

                            <div className="col-md-3">
                                <label htmlFor="fullName" className="form-label">Nome Completo <span style={{ color: 'red' }}>*</span></label>
                                <input type="text"  onChange={(e)=> setFullName(e.target.value)} 
                                    value={fullName && fullName}  className={`form-control ${fullName === '' ? 'empty-field' : ''}`}
                                    id="fullName"
                                />

                            </div>

                            <div className="col-3">
                                <label htmlFor="cpf" className="form-label">CPF <span style={{ color: 'red' }}>*</span></label>

                                <InputMask mask="999.999.999-99" className="form-control" 
                                    onChange={(e)=> setCpf(e.target.value)} value={cpf && cpf}
                                    id="cpf"
                                /> 

                            </div>

                            <div className="col-md-2">
                                <label htmlFor="birthDateDriver" className="form-label">Data de nascimento <span style={{ color: 'red' }}>*</span></label>
                                <input type="date" onChange={(e)=> setBirthDate(e.target.value)}  value={birthDate && birthDate}  className="form-control" id="birthDateDriver"/>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-3">

                                <label htmlFor="contactPersonal" className="form-label">Contato <span style={{ color: 'red' }}>*</span></label>
                                    <InputMask mask="(99) 99999-9999" className="form-control" 
                                        onChange={(e)=> setContact(e.target.value)} 
                                        value={contact && contact} 
                                        id="contact"/>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="sexGender" className="form-label">Gênero <span style={{ color: 'red' }}>*</span></label>
                                <select className="form-select" id="formOfPayment" value={sexGender && sexGender}  onChange={(e)=> setSexGender(e.target.value)} aria-label="">
                                    <option defaultValue="">Selecione</option>
                                    <option value="Masculino">Masculino</option>
                                    <option value="Feminino">Feminino</option>
                                    <option value="Outro">Outro</option>
                                    <option value="Prefiro não dizer">Prefiro não dizer</option>
                                </select>

                            </div>

                            <div className="col-md-2  mb-3">
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-3 ">
                                        <label htmlFor="email" className="form-label">E-mail (Login do motorista) <span style={{ color: 'red' }}>*</span></label>
                                        <input type="text"  onChange={(e)=> setEmail(e.target.value)} 
                                        value={email && email}  className="form-control" id="email"/>
                            </div>

                            <div className="col-3">
                                <label htmlFor="Senha" className="form-label">Senha <span style={{ color: 'red' }}>*</span></label>
                                {/* <input type="password" onChange={(e)=> setPassword(e.target.value)} 
                                value={password && password}  className="form-control" id="password"/> */}
                                <PasswordInput onChange={(value) => setPassword(value)} id="password" />
                            </div>
                            <div className="col-3">
                                <label htmlFor="Confirmar Senha" className="form-label">Confirmar Senha <span style={{ color: 'red' }}>*</span></label>
                                {/* <input type="password" onChange={(e)=> setConfirmPassword(e.target.value)} 
                                value={confirmPassword && confirmPassword}  className="form-control" id="confirmPassword"/> */}
                                <PasswordInput onChange={(value) => setConfirmPassword(value)} id="confirmPassword" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-signin mx-auto mb-4 mb-lg-6">

                    <div className="col-12">
                        <h4 className="mb-3">Documentos Gerais</h4>
                    </div>
                    <div className="row">
                        <div className="row">
                            <div className="col-md-2">
                                <label htmlFor="documentCnhExpiration" className="form-label">Data de expiração CNH <span style={{ color: 'red' }}>*</span></label>
                                <input type="date"  onChange={(e)=> setDocumentCnhExpiration(e.target.value)} value={documentCnhExpiration && documentCnhExpiration}  className="form-control" id="documentCnhExpiration"/>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <label tabIndex={"0"} id="documentAnttFrontImg" htmlFor="documentAnttFrontImg"  className="form-label">Doc ANTT Frente <span style={{ color: 'red' }}>*</span></label>
                            </div>

                            {
                                documentAnttFrontImg ?
                                <>

                                    <div className="row">
                                        <div className="col-md-2">
                                            <ImageZoom 
                                                title=""
                                                source={documentAnttFrontImg && documentAnttFrontImg}
                                                height="100"
                                                width="100"
                                            />
                                            <Button variant="outlined" 
                                                onClick={(e) => {
                                                    handleClickOpen();
                                                    setOrigem("ANTT Frente");
                                                }} >
                                                Editar
                                            </Button>
                                        </div>
                                    </div>

                                    
                                </>
                                : 
                                <Button variant="outlined" 
                                onClick={(e) => {
                                    handleClickOpen();
                                    setOrigem("ANTT Frente");
                                }}>
                                    Importar imagem
                                </Button>
                            }

                        </div>

                        <div className="col-2">
                            
                            <div>
                                <label tabIndex={"0"} id="documentAnttVerseImg" htmlFor="documentAnttVerseImg"  className="form-label">ANTT Verso <span style={{ color: 'red' }}>*</span></label>
                            </div>

                            {
                                documentAnttVerseImg ?
                                <>
                                    <div className="row">
                                        <div className="col-md-2">
                                        <ImageZoom 
                                            title=""
                                            source={documentAnttVerseImg && documentAnttVerseImg}
                                            height="100"
                                            width="100"
                                        />
                                        <Button variant="outlined" 
                                            onClick={(e) => {
                                                handleClickOpen();
                                                setOrigem("ANTT Verso");
                                            }} >
                                            Editar
                                        </Button>
                                        </div>
                                    </div>
                                </>
                                : 
                                <Button variant="outlined" 
                                onClick={(e) => {
                                    handleClickOpen();
                                    setOrigem("ANTT Verso");
                                }}>
                                    Importar imagem
                                </Button>
                            }

                        </div>
                        <div className="col-2">
                            <div>
                                <label tabIndex={"0"} id="documentCnhFrontImg" htmlFor="documentCnhFrontImg" className="form-label">CNH Frente  <span style={{ color: 'red' }}>*</span></label>
                            </div>

                            {
                                documentCnhFrontImg ?
                                <>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <ImageZoom 
                                                title=""
                                                source={documentCnhFrontImg && documentCnhFrontImg}
                                                height="100"
                                                width="100"
                                            />
                                            <Button variant="outlined" 
                                                onClick={(e) => {
                                                    handleClickOpen();
                                                    setOrigem("CNH Frente");
                                                }} >
                                                Editar
                                            </Button>
                                        </div>
                                    </div>
                                </>
                                : 
                                <Button variant="outlined" 
                                    onClick={(e) => {
                                        handleClickOpen();
                                        setOrigem("CNH Frente");
                                    }}>
                                    Importar imagem
                                </Button>
                            }

                        </div>
                        <div className="col-md-2">
                            <div>
                                <label  tabIndex={"0"} id="documentCnhVerseImg" htmlFor="originStateInitial" className="form-label">CNH Verso <span style={{ color: 'red' }}>*</span></label>
                            </div>

                            {
                                documentCnhVerseImg ?
                                <>

                                    <div className="row">
                                        <div className="col-md-2">
                                        <ImageZoom 
                                            title=""
                                            source={documentCnhVerseImg && documentCnhVerseImg}
                                            height="100"
                                            width="100"
                                        />
                                        <Button variant="outlined" 
                                            onClick={(e) => {
                                                handleClickOpen();
                                                setOrigem("CNH Verso");
                                            }} >
                                            Editar
                                        </Button>

                                        </div>
                                    </div>        
                                </>
                                : 
                                <Button variant="outlined" 
                                    onClick={(e) => {
                                        handleClickOpen();
                                        setOrigem("CNH Verso");
                                    }}>
                                    Importar imagem
                                </Button>
                            }
                        </div>

                    </div>

                </div>
                
                {/** Address */}
                <div className="form-signin mx-auto mb-4 mb-lg-6">

                    <div className="col-12">
                        <h4 className="mb-3">Endereço</h4>
                    </div>
                    <div className="row">

                        <div className="row">
                            <div className="col-2">
                                <label htmlFor="cnpjDriver" className="form-label">CEP</label>                                

                                    <InputMask mask="99999-999" className="form-control" 
                                        onChange={(e)=> {
                                            buscarCep(e.target.value);
                                            setCep(e.target.value);
                                        }} value={cep && cep} placeholder="99999-999"
                                    />
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="streetDriver" className="form-label">Logradouro</label>
                                <input type="text"  onChange={(e)=> setStreet(e.target.value)} value={street && street}  className="form-control" id="streetDriver"/>
                            </div>
                            <div className="col-1">
                                <label htmlFor="numberDriver" className="form-label">Número</label>
                                <input type="text"  onChange={(e)=> setNumber(e.target.value)} value={number && number}  className="form-control" id="numberDriver" placeholder=""/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="districtDriver" className="form-label">Bairro</label>
                                <input type="text" onChange={(e)=> setDistrict(e.target.value)} value={district && district} className="form-control" id="districtDriver"/>
                            </div>

                        </div>
                        <div className="row">    
                            <div className="col-3">
                                <label htmlFor="cityDriver"  className="form-label">Cidade</label>
                                <input type="text" onChange={(e)=> {
                                    setCity(e.target.value)
                                }} value={city && city} maxLength={10} className="form-control" id="cityDriver" placeholder=""/>

                            </div>
                            <div className="col-md-1">
                                <label htmlFor="originStateInitial" className="form-label">Estado</label>
                                <input type="text"  onChange={(e)=> setState(e.target.value)} value={state && state}  className="form-control" id="stateDriver"/>
                            </div>

                        </div>

                    </div>
                </div>

                {/** Kinships */}
                <div className="form-signin mx-auto mb-4 mb-lg-6">

                        <div className="col-12">
                            <h4 className="mb-3">Contatos de parentes</h4>
                        </div>
                        <div className="row">

                            <div className="row">

                                <div className="col-md-4">
                                    <label htmlFor="birthDateDriver" className="form-label">Nome</label>
                                    <input type="text"  onChange={(e)=> setNameKinships1(e.target.value)} 
                                    value={nameKinships1 && nameKinships1}  className="form-control" id="birthDateDriver"/>
                                </div>
                                <div className="col-md-2">
                               <label htmlFor="fullName" className="form-label">Contato</label>
                                    <InputMask mask="(99) 99999-9999" className="form-control" 
                                    onChange={(e)=> setContactKinships1(e.target.value)} 
                                    value={contactKinships1 && contactKinships1} 
                                    placeholder="(99) 99999-9999"/>

                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="fullName" className="form-label">Grau</label>

                                    <select className="form-select" id="degreeKinship1" value={degreeKinships1 && degreeKinships1} onChange={(e)=> setDegreeKinships1(e.target.value)} aria-label="">
                                        <option defaultValue="">Selecione</option>
                                        <option value="Mãe/Pai">Mãe/Pai</option>
                                        <option value="Filha/filho">Filha/filho</option>
                                        <option value="Irmã/Irmão">Irmã/Irmão</option>
                                        <option value="Tia/Tio">Tia/Tio</option>
                                        <option value="Prima/Primo">Prima/Primo</option>
                                    </select>

                                </div>


                            </div>

                            <div className="row">

                                <div className="col-4">
                                    <label htmlFor="nameKinships2"  className="form-label">Nome</label>
                                    <input type="text" onChange={(e)=> {
                                        setNameKinships2(e.target.value)
                                    }} value={nameKinships2 && nameKinships2} maxLength={10} className="form-control" id="nameKinships2" placeholder=""/>

                                </div>
                                <div className="col-2">
                                    <label htmlFor="documentAnttFrontImg"  className="form-label">Contato</label>
                                    <InputMask mask="(99) 99999-9999" className="form-control" 
                                        onChange={(e)=> setContactKinships2(e.target.value)} 
                                        value={contactKinships2 && contactKinships2} 
                                        placeholder="(99) 99999-9999"/>
                                </div>

                                <div className="col-md-2">
                                    <label htmlFor="fullName" className="form-label">Grau</label>

                                    <select className="form-select" id="formOfPayment" value={degreeKinships2 && degreeKinships2}  onChange={(e)=> setDegreeKinships2(e.target.value)} aria-label="">
                                        <option defaultValue="">Selecione</option>
                                        <option value="Mãe/Pai">Mãe/Pai</option>
                                        <option value="Filha/filho">Filha/filho</option>
                                        <option value="Irmã/Irmão">Irmã/Irmão</option>
                                        <option value="Tia/Tio">Tia/Tio</option>
                                        <option value="Prima/Primo">Prima/Primo</option>
                                    </select>

                                </div>

                            </div>

                        </div>
                    </div>

                {/** Vehicle */}
                <div className="form-signin mx-auto mb-4 mb-lg-6">

                        <div className="col-12">
                            <h4 className="mb-3">Veículo</h4>
                        </div>
                        <div className="row">

                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="bodyworkPlate" className="form-label">Placa Carroceria</label>
                                    <input type="text" onChange={(e)=> {
                                        setBodyworkPlate(e.target.value)
                                    }} value={bodyworkPlate && bodyworkPlate} className="form-control" id="bodyworkPlate" placeholder=""/>

                                </div>
                                
                                <div className="col-3">
                                    <label htmlFor="bodyworkType"  className="form-label">Tipo de carroceria</label>

                                    <select className="form-select" id="formOfPayment" 
                                            value={bodyworkType && bodyworkType}  onChange={(e)=> setBodyworkType(e.target.value)} aria-label="">
                                        <option defaultValue="">Selecione</option>
                                        <option value="BOBINEIRA">BOBINEIRA</option>
                                        <option value="GRADE BAIXA">GRADE BAIXA</option>
                                        <option value="BAÚSECO">BAÚSECO</option>
                                        <option value="BAÚ FRIGORIFICO">BAÚ FRIGORIFICO</option>
                                        <option value="ABERTO">ABERTO</option>
                                        <option value="BAÚ">BAÚ</option>
                                        <option value="GRANELEIRO">GRANELEIRO</option>
                                        <option value="SIDER">SIDER</option>
                                        <option value="PRANCHA">PRANCHA</option>
                                        <option value="TANQUE">TANQUE</option>
                                        <option value="CAÇAMBA">CAÇAMBA</option>
                                        <option value="PORTA CONTEINER">PORTA CONTEINER</option>
                                        <option value="CILO">CILO</option>
                                        <option value="CEGONHA">CEGONHA</option>
                                    </select>
                                </div>
                                
                                <div className="col-md-2">
                                    <div>
                                        <label htmlFor="vehicleImg" className="form-label">Imagem do veículo</label>
                                    </div>

                                    {
                                        vehicleImg ?
                                        <>

                                            <div className="row">
                                                <div className="col-md-2">
                                                    <ImageZoom 
                                                        title=""
                                                        source={vehicleImg && vehicleImg}
                                                        height="80"
                                                        width="50"
                                                    />
                                                    <Button variant="outlined" 
                                                        onClick={(e) => {
                                                            handleClickOpen();
                                                            setOrigem("Imagem veiculo");
                                                        }} >
                                                        Editar
                                                    </Button>
                                                </div>
                                            </div>                                            
                                        </>
                                        : 
                                        <Button variant="outlined" 
                                        onClick={(e) => {
                                            handleClickOpen();
                                            setOrigem("Imagem veiculo");
                                        }}>
                                            Importar imagem
                                        </Button>
                                    }

                                </div>

                                <div className="row">
                                    <div className="col-2">
                                        <label htmlFor="vehiclePlate" className="form-label">Placa do veículo</label>
                                        <input type="text"  onChange={(e)=> setVehiclePlate(e.target.value)} value={vehiclePlate && vehiclePlate}  className="form-control" id="vehiclePlate" placeholder=""/>
                                    </div>

                                    <div className="col-md-2">
                                        <label htmlFor="vehicleType" className="form-label">Tipo de veículo</label>
                                        <select className="form-select" id="formOfPayment" 
                                                value={vehicleType && vehicleType}  onChange={(e)=> setVehicleType(e.target.value)} aria-label="">
                                            <option defaultValue="">Selecione</option>
                                            <option value="FIORINO">FIORINO</option>
                                            <option value="TRUCK">TRUCK</option>
                                            <option value="BI-TRUCK">BI-TRUCK</option>
                                            <option value="CARRETA S">CARRETA S</option>
                                            <option value="CARRETA LS">CARRETA LS</option>
                                            <option value="TOCO">TOCO</option>
                                            <option value="3/4">3/4</option>
                                            <option value="VUC">VUC</option>
                                            <option value="BITREM">BITREM</option>
                                            <option value="RODOTREM">RODOTREM</option>
                                            <option value="MUNK">MUNK</option>
                                            <option value="VANDERLEIA">VANDERLEIA</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                </div>



                {/** Dados bancários */}
                <div className="form-signin mx-auto mb-4 mb-lg-6">

                        <div className="col-12">
                            <h4 className="mb-3">Dados bancários</h4>
                        </div>
                        <div className="row">

                            <div className="row">
                                
                                <div className="row">

                                    <div className="col-3">
                                        <label htmlFor="bankName" className="form-label">Nome do banco</label>
                                        <input type="text"  onChange={(e)=> setBankName(e.target.value)} 
                                        value={bankName && bankName}  className="form-control" id="bankName"/>
                                    </div>

                                    <div className="col-1">
                                        <label htmlFor="code" className="form-label">Código</label>
                                        <input type="text"  onChange={(e)=> setCode(e.target.value)} 
                                        value={code && code}  className="form-control" id="code"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2">
                                        <label htmlFor="agency" className="form-label">Agência</label>
                                        <input type="text" onChange={(e)=> setAgency(e.target.value)} 
                                        value={agency && agency} className="form-control" id="agency"/>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="accountNumber"  className="form-label">Número da conta</label>
                                        <input type="text" onChange={(e)=> {
                                            setAccountNumber(e.target.value)
                                        }} value={accountNumber && accountNumber} className="form-control" id="accountNumber" placeholder=""/>

                                    </div>

                                    <div className="col-1">
                                        <label htmlFor="accountDigit" className="form-label">Digito</label>
                                        <input type="text" onChange={(e)=> {
                                            setAccountDigit(e.target.value)
                                        }} value={accountDigit && accountDigit} className="form-control" id="accountDigit" placeholder=""/>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label htmlFor="holderAccount" className="form-label">Propríetário da conta</label>
                                    <input type="text"  onChange={(e)=> setHolderAccount(e.target.value)} 
                                    value={holderAccount && holderAccount}  className="form-control" id="holderAccount"/>
                                </div>
                                
                                <div className="col-md-3">
                                    <label htmlFor="pix" className="form-label">PIX</label>
                                    <input type="text"  onChange={(e)=> setPix(e.target.value)} 
                                    value={pix && pix}  className="form-control" id="pix"/>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                
                {/** Profissional reference */}
                <div className="form-signin mx-auto mb-4 mb-lg-6">


                        <div className="col-12">
                            <h4 className="mb-3">Contato profissional</h4> 
                        </div>

                        <div className="row">

                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="nameProfessional"  className="form-label">Nome</label>
                                    <input type="text" onChange={(e)=> {
                                        setNameProfessional(e.target.value)
                                    }} value={nameProfessional && nameProfessional} className="form-control" id="nameProfessional" placeholder=""/>

                                </div>
    
                                <div className="col-3">
                                    <label htmlFor="contactProfessional" className="form-label">Contato </label>
                                    <InputMask mask="(99) 99999-9999" className="form-control" 
                                        onChange={(e)=> setContactProfessional(e.target.value)} 
                                        value={contactProfessional && contactProfessional}
                                        placeholder="(99) 99999-9999"/>

                                </div>
                                
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="nameProfessional1"  className="form-label">Nome</label>
                                    <input type="text" onChange={(e)=> {
                                        setNameProfessional1(e.target.value)
                                    }} value={nameProfessional1 && nameProfessional1} className="form-control" id="nameProfessional1" placeholder=""/>

                                </div>
                                <div className="col-3">
                                    <label htmlFor="contactProfessional1" className="form-label">Contato </label>
                                    <InputMask mask="(99) 99999-9999" className="form-control" 
                                        onChange={(e)=> setContactProfessional1(e.target.value)} 
                                        value={contactProfessional1 && contactProfessional1}
                                        placeholder="(99) 99999-9999"/>

                                </div>
                                

                            </div>

                        </div>
                    </div>

                <div className="form-signin mx-auto mb-4 mb-lg-6">

                    <div className="col-12">
                        <h4 className="mb-3">Histórico de atualização</h4> 
                    </div>

                    <div className="row">

                        
                        <div className="col-md-2">
                            <label htmlFor="createdDriver" className="form-label">Data de criação</label>
                            <input type="date"  onChange={(e)=> setCreated(e.target.value)} disabled={true} value={created && created}  className="form-control" id="createdDriver"/>
                        </div>
                        
                        <div className="col-md-2">
                            <label htmlFor="originStateInitial" className="form-label">Atualizacão app</label>
                            <input type="date"  onChange={(e)=> setLastUpdated(e.target.value)} disabled={true} value={lastUpdated && lastUpdated}  className="form-control" id="lastUpdatedDriver"/>
                        </div>

                        <div className="col-md-2">
                            <label htmlFor="lastUpdatedBackOffice" className="form-label">BackOffice</label>
                            <input type="date"  onChange={(e)=> setLastUpdatedBackOffice(e.target.value)} disabled={true} value={lastUpdatedBackOffice && lastUpdatedBackOffice}  className="form-control" id="lastUpdatedBackOffice"/>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="dateRenovation" className="form-label">Data renovação</label>
                            <input type="date"  onChange={(e)=> setDateRenovation(e.target.value)} value={dateRenovation && dateRenovation}  className="form-control" id="dateRenovation"/>
                        </div>
                        
                        <hr className="my-4"/>
                    </div>
                </div>
                
                <div className="container">
                        
                        <div className="row">
                            <div className="col-5">
                            
                            </div>
                            <div className="col-5">
                            
                            </div>
                            <div className="col-2">
                                <div className="btn-class-cadastrar">
                                    {
                                        carregando ? 
                                        <div className="spinner-border text-danger" role="status">
                                        <span className="visually-hidden ">Loading...</span></div>
                                        :
                                        <button type="submit" onClick={id ? update : save} className="w-100 btn btn-primary btn-cadastrar">{'Salvar'}</button>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <div>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                                <DialogTitle id="alert-dialog-title">
                                <h1>
                                    <i  class="fa fa-exclamation-triangle" style={{color: '#FEDE00'}} aria-hidden="true"></i> 
                                    {title}
                                </h1>
                                
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                    <h6> {msg} </h6>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                
                                    <button 
                                        type="button" 
                                        onClick={handleCloseDialog} 
                                        className="btn btn-primary btn-cadastrar"> Ok</button>

                                </DialogActions>
                            </Dialog>
                        </div>

            </div> //div principal
        }/>

        :
            <Navigate to='/login' />
        }
    </>
    )

}

export default Driver;