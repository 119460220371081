import firebase from '../../src/config/firebase';
require('firebase/auth')

const driverService = {
    
    async getDriverAvailable(id){

        return firebase.firestore()
            .collection('drivers_users')
            .doc(id)
            .get();
    },


    async saveMyFreightToDriver(idDriver, data){
        console.log("Adicionando o frete ao motorista " + data)

        console.log(data)

        firebase.firestore()
            .collection('drivers_users')
            .doc(idDriver)
            .collection('myFreightsList')
            .add(data)
            // .doc(idFreight)
            // .update(data)
            .catch(error => {
                console.log(error)
            });
    },


    async saveStatusMyFreight(idDriver, idFreight, status){
        console.log("salvando status na fila do MyFrete" + status)

        var data = {
            status: status
        }

        console.log(data)

        firebase.firestore()
            .collection('drivers_users')
            .doc(idDriver)
            .collection('myFreightsList')
            .doc(idFreight)
            .update(data)
            .catch(error => {
                console.log(error)
            });
    },

    async saveMyFreight(idDriver, idFreight, data){
        console.log("salvando status na fila do MyFrete")

        console.log(data)

        firebase.firestore()
                .collection('drivers_users')
                .doc(idDriver)
                .collection('myFreightsList')
                .doc(idFreight)
                .set(data)
            .catch(error => {
                console.log(error)
            });
    },

    async saveClient(clientData) {

        try {
            await firebase.firestore()
                .collection("client")
                .doc()
                .set(clientData);
    
            // alert("Dados atualizados com sucesso!");
        } catch (error) {
            console.error("Erro ao adicionar dados:", error);
        }
    },

    async saveMessageInMyFreight(idDriver, title, message){
        console.log("salvando mensagens na fila do MyFrete ")

        var data = {
            title: title,
            message: message,
            created: new Date()
        }

        console.log(data)

        firebase.firestore()
            .collection('drivers_users')
            .doc(idDriver)
            .collection('notifications')
            .add(data)
            .catch(error => {
                console.log(error)
            });

    },


    async deleteStatusMyFreight(idDriver, idFreight){
        console.log("Deletando status na fila do MyFrete")
        
        firebase.firestore()
            .collection('drivers_users')
            .doc(idDriver)
            .collection('myFreightsList')
            .doc(idFreight)
            .delete()
            .catch(error => {
                console.log(error) 
            });

    },

    
    async deleteMyDriver(idDriver){
        console.log("Deletando motorista privado - MyDriver " + idDriver)
        
        firebase.firestore()
            .collection('my_driver')
            .doc(idDriver)
            .delete()
            .catch(error => {
                console.log(error) 
            });
    }


}

export default driverService;